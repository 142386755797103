@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;900&display=swap');

body {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body * {
  font-family: 'Exo 2', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
}

#root {
  overflow-x: hidden;
}

.noScrollX {
  overflow-x: hidden !important;
}

*::-webkit-scrollbar {
  width: 0px;
}

.link-effect {
  transition: 0.3s all;
}

.link-effect:hover {
  color: #aeedee;
}

@media screen and (min-width: 450px) and (max-width: 900px) {
  img:not(.no-descale) {
    transform: scale(0.7);
  }
}
